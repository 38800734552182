import React, {useEffect, useState, useRef} from "react";
import {useLocation} from "react-router";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import PasswordLoginManager from "../../../apps/IsHealth/Common/PasswordLogin/PasswordLoginManager"
import { forStatement } from "@babel/types";

const ResolveBindHN = props => {

  // https://reg.ishealth.app/bind-hn/?
  // hn=C950464
  // email=ishealthreg%40gmail.com&
  // first_name=%E0%B8%81%E0%B8%B8%E0%B8%AA%E0%B8%B8%E0%B8%A1%E0%B8%B2&
  // last_name=%E0%B9%81%E0%B8%A2%E0%B9%89%E0%B8%A1%E0%B9%80%E0%B8%81%E0%B8%95%E0%B8%B8&
  // time=2021-01-06T15%3A15%3A45.046673
  // &division=CNMICNMR&
  // role=CNMI&
  // sign=I%2BIIPzpc2NQCeJZLLdvzL2X7arfwZeJYD9%2F1xElD7v9moWEjHz4nBqKMKvmOrAtKUFJExV1LzAxlpreuixV%2FhA%3D%3D&
  // auth_ver=1

  // https://ishealth-nurse-staging.firebaseapp.com/
  // resolve-chat/
  // ?hn=C950464&
  // email=billpranee001%40gmail.com&
  // first_name=%E0%B9%80%E0%B8%88%E0%B9%89%E0%B8%B2%E0%B8%AB%E0%B8%99%E0%B9%89%E0%B8%B2%E0%B8%97%E0%B8%B5%E0%B9%88&
  // last_name=%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B9%80%E0%B8%87%E0%B8%B4%E0%B8%99&time=2021-01-06T15%3A00%3A28.006531
  // &division=CNMICNPSY&r
  // ole=CNMI&
  // sign=YEIRU1A2vC5zNezipgce2%2BHHd6qrYb3224gECeyv4ukt70EtMB0QEF8eIP2xNAX3lo0ehNkkrFqCu0xgReYxFA%3D%3D&
  // auth_ver=1

  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const hisDivision = params.get("division");

  const authVer = params.get("auth_ver");
  const email = params.get("email");
  const firstName = params.get("first_name");
  const lastName = params.get("last_name");
  const time = params.get("time");
  const sign = params.get("sign");
  const role = params.get("role");
  const division = params.get("division");

  const [divisionId, setDivisionId] = useState(null);
  const [error, setError] = useState(null)
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const isMounted = useRef(true);
  const manager = new PasswordLoginManager();

  useEffect(() => {
    if(authVer && email && firstName && lastName && time && sign && role && division){
      console.log("getCredential")
      getCredential()
    } else {
      console.log("Access denined")
      setError("Access denied")
    }
  }, [authVer, email, firstName, lastName, time, sign, role, division])

  const getCredential = async () => {
    setError(null);
    console.log(" Called getCredential")
    const token = await manager.requestTokenByCredential({
      email,
      firstName,
      lastName,
      time,
      sign,
      authVer,
      role,
      division
    })
    console.log("token ", token)
    if(token){
      props.onLoginSuccess(token)
    } else {
      setError("Access denied")
    }
  }

  // useEffect(() => {
  //   const getResolveChatChannel = async () => {
  //     setError(null)
  //     const [data, error, network] = await props.controller.getResolveChatChannel({
  //       divisionId: divisionId,
  //       apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken,
  //     });
  //     if (isMounted.current) {
  //       if (error) {
  //         console.log(error);
  //         setError("เปิดแชทไม่สำเร็จ ไม่พบ Encounter นี้")
  //       } else {
  //         console.log({data,error,network})
  //         history.push(`/Chat/${data.encounter}/${data.chat_channel_id}/`)
  //       }
  //     }
  //   }
  //   if(divisionId && (props.apiToken || cookies.apiToken)){
  //     getResolveChatChannel()
  //   }
  // }, [divisionId, props.apiToken, cookies.apiToken])
  
  // useEffect(() => {
  //   const getResolveDivisionProfile = async () => {
  //     setError(null)
  //     const [data, error, network] = await props.controller.getDivisionProfile({
  //       divisionCode: hisDivision,
  //       apiToken: cookies.apiToken ? cookies.apiToken : props.apiToken
  //     });
  //     if (isMounted.current) {
  //       if(data?.items && data.items[0]?.division){
  //         setDivisionId(data.items[0].division)
  //       } else if (data?.items && data.items.length === 0){
  //         setError("ผู้ใช้ไม่ได้อยู่ในหน่วยงานนี้")
  //       } else {
  //         let err = error;
  //         if(typeof(err) === "object"){
  //           for(let key in error){
  //             err = `${key}: ${error[key]} \n`
  //           }
  //         }
  //         else if (error.length > 200) {
  //           console.log(network.response)
  //           err = `${network.response.status}: ${network.response.statusText}`;
  //         }
  //         setError(err)
  //       }
  //     }
  //   };

  //   if (hisDivision && (props.apiToken || cookies.apiToken)) {
  //     getResolveDivisionProfile();
  //   }
  // }, [hisDivision, props.apiToken, cookies.apiToken]);

  if(error){
    return <h1 className="resolve-bindhn">{error}</h1>
  }
  if(!hisDivision){
    return <h1  className="resolve-bindhn">ไม่สามารถระบุหน่วยงาน</h1>
  }
  return (
    <h1  className="resolve-bindhn">Redirecting to BindHN...</h1>
  )
}

export default ResolveBindHN;