import React, { useState, useRef } from "react";
import {Dimmer, Loader, Button, Icon, Form} from "semantic-ui-react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "semantic-ui-css/semantic.min.css";
import LineLoginButton from "../react-lib/apps/IsHealth/Common/Line/LoginButton";
import FBLoginButton from "../react-lib/apps/IsHealth/Common/FacebookLogin/FBLoginButton";
import config from "../config/config";
import LoginForm from "../react-lib/apps/IsHealth/Common/PasswordLogin/LoginForm.tsx";

const REGLogin = props => {
  const history = useHistory();
  const lineButtonRef = useRef();
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie] = useCookies([]);

  const loadFbLoginApi = () => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: "3314489911914234",
        cookie: true,
        xfbml: true,
        version: "v7.0"
      });
      window.FB.AppEvents.logPageView();
      window.FB.Event.subscribe("auth.statusChange", response => {
        if (response.authResponse) {
          checkLoginState();
        } else {
          checkLoginState();
        }
      });
    };

    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/th_TH/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  const responseFacebook = async fbResponse => {
    if (
      fbResponse.status === "not_authorized" ||
      fbResponse.status === "unknown"
    ) {
      return;
    }
    setIsLoading(true);
    if (fbResponse.authResponse && fbResponse.authResponse.accessToken) {
      if (fbResponse.authResponse) {
        props.onLoginFacebookSiteSuccess(fbResponse.authResponse);
        await window.FB.api(
          "/me",
          "get",
          { fields: "email,name,picture" },
          async res => {
            if (!("email" in res)) {
              alert("User ไม่ได้อนุญาติ ให้เข้าถึง email-address");
              setIsLoading(false);
              props.openSetupEmail();
              return;
            }

            let [
              response,
              error,
              network
            ] = await props.controller.getRegisterByTokenFacebook({
              accessToken: fbResponse.authResponse.accessToken,
              app: "ishealth"
            });

            if (response) {
              props.onLoginSuccess(response);
            } else {
              setIsLoading(false);
            }
          }
        );
      }
    }
  };

  const gotoLineLogin = () => {
    if (props.lineLoginManager) {
      props.lineLoginManager.openLogin();
    }
  };

  React.useEffect(() => {
    loadFbLoginApi();
    if (cookies.apiToken) {
      props.onHaveApiToken();
    } else {
    }
  }, []);

  const checkLoginState = () => {
    window.FB.getLoginStatus(response => {
      responseFacebook(response);
    });
  };

  const handleFBClick = () => {
    window.FB.login();
  };

  return (
    <Dimmer.Dimmable dimmed={isLoading}>
      <div className="Login">
        <div className="fbLogin">
          {
            config.PENTA_ACC_LINE_LOGIN ?
            <LineLoginButton
                onClick={gotoLineLogin}
                ref={lineButtonRef}
                textStyle={{margin: "25px 70px"}}
                logoStyle={{width: "40px", height: "40px"}}
                style={{height: "40px", margin: "auto", marginBottom: "30px", maxWidth: "284px", display: "block"}}
            /> : null
          }
          { config.PENTA_ACC_FACEBOOK_LOGIN ?
            <FBLoginButton
              onClick={handleFBClick}
              style={{height: "40px", margin: "auto", marginBottom: "30px", maxWidth: "284px", display: "block"}}
              textStyle={{margin: "25px 45px"}}
              logoStyle={{height: "40px", padding: "5px"}}
            /> : null
          }
          { config.PENTA_ACC_EMAIL_LOGIN ?
            <div
              onClick={() => {
                history.push("/EmailLogin");
              }}
              className="email-login-button"
            >
              <Icon style={{ padding: "5px", float: "left" }} name="mail" size="big"/>
              <span style={{margin: "0 10px"}}>ลงชื่อเข้าใช้งานด้วยอีเมล</span>
            </div> : null
          }
        </div>
        { config.USERNAME_PASSWORD_LOGIN ? <div className="pwdLogin">
          <LoginForm
            usernameText="Email or phone no."
            onLoginSuccess={props.onLoginSuccess}
          />
        </div> : null}
      </div>
      <Dimmer active={isLoading} inverted>
        <Loader size="massive">{"Loading"}</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

REGLogin.defaultProps = {
  controller: null,
  lineLoginManager: null,
  onLoginSuccess: () => {},
  onLoginFacebookSiteSuccess: () => {},
  onHaveApiToken: () => {},
  openSetupEmail: () => {},
  redirectUri: null
};

REGLogin.propTypes = {
  controller: PropTypes.object,
  lineLoginManager: PropTypes.object,
  onLoginSuccess: PropTypes.func,
  onLoginFacebookSiteSuccess: PropTypes.func,
  onHaveApiToken: PropTypes.func,
  openSetupEmail: PropTypes.func,
  redirectUri: PropTypes.string
};

export default REGLogin;
