import React, { Component } from "react";
import "semantic-ui-css/semantic.css";
import "react-table-6/react-table.css";
import MainScreen from "./MainScreen";
import REGLogin from "./REGLogin";

import {
  withRouter,
  Switch,
  Route,
  RouteComponentProps,
} from "react-router-dom";
// import {RouteComponentProps} from "react-router";
import { Button, Menu, Dropdown } from "semantic-ui-react";

import DPIManager from "../react-lib/apis/manager/DPIManager";
import MixInManager from "../react-lib/apis/manager/MixInManager";
import MSGManager from "../react-lib/apis/manager/MSGManager";
import PRXManager from "../react-lib/apis/manager/PRXManager";
import UserManager from "../react-lib/apis/manager/UserManager";
import PTMManager from "../react-lib/apis/manager/PTMManager";
import REGManager from "../react-lib/apis/manager/REGManager";
import CoreManager from "../react-lib/apis/manager/CoreManager";
import HRMManager from "../react-lib/apis/manager/HRMManager";
import LineLoginManager from "../react-lib/apps/IsHealth/Common/LineLoginManager";
import QUEManager from "../react-lib/apis/manager/QUEManager";
import BILManager from "../react-lib/apis/manager/BILManager";

import QueueController from "../react-lib/apps/QUE/QueueController";
import { ChatController } from "../react-lib/apps/IsHealth/Chat";
import REGChatController from "../react-lib/apps/IsHealth/REG/ChatController";
import AppController from "../react-lib/frameworks/AppController";
import {
  CardClassifyController,
  CardDiagFormHistoryController,
  CardPatientPanelController,
  CardNurseNoteController,
  CardProgressNoteController,
} from "../react-lib/apps/IsHealth/Nurse";
import BilPatientPanelController from "../react-lib/apps/IsHealth/BIL/BilPatientPanelController";

import DashboardController from "../react-lib/apps/IsHealth/Dashboard/DashboardController";
import AppointmentDashboardController from "../react-lib/apps/IsHealth/APP/AppointmentDashboardController";
import BindHNPageController from "../react-lib/apps/IsHealth/REG/BindHNPageController";
import CardVitalSignController from "../react-lib/apps/PHV/CardVitalSignController";
import ResolveBindHN from "../react-lib/apps/IsHealth/Common/ResolveBindHN";

import * as IsHealthCommon from "../react-lib/apps/IsHealth/Common";
import { CookiesProvider, withCookies, ReactCookieProps } from "react-cookie";
import config from "../config/config";
import EmailLogin from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailLogin";
import EmailRegister from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailRegister";
import EmailCallback from "../react-lib/apps/IsHealth/Common/EmailLogin/EmailCallback";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
import * as serviceWorker from "../serviceWorker";
import "../css/main.scss";
import "../react-lib/apps/QUE/Time.css";
import "../react-lib/apps/IsHealth/Common/FacebookLogin/LoginButton.css";
import CertLogin from "../react-lib/apps/IsHealth/Common/CertLogin/CertLogin";

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  PENGUIN: "penguin",
};

const URL_PREFIX = {
  PENGUIN: "penguin",
};

interface AppState {
  apiToken?: string;
  fullname?: string;
  username?: string;
  userId?: string | number;
  landingLoading: boolean;
  providerId?: string | number;
  theme?: string;
  myDivisions?: [];
  currentDivision?: string | number | { value?: string | number };
  division?: string | number;
  finishedTriageLevel?: [];
  providerInfo: {};
  credentailLoginSuccess: boolean;
}

interface AppProps extends RouteComponentProps, ReactCookieProps { }

class MainREG extends Component<AppProps, AppState> {
  private mainScreen: {
    chatTable?: {
      chatListRef?: {
        getChatList?: () => {};
        getFilterValue?: () => {};
      };
      chatBoxRef?: {
        receivedMessage?: ({ chatChannelId }) => {};
        receivedMessageRead?: ({ chatChannelId }) => {};
        receivedReclassify?: ({
          filterValue,
          encounterId,
          chatChannelId,
        }) => {};
      };
      getPatientInfo?: () => {};
    };
  } | null;
  private loginController: {};
  private mixInManager: {};
  private prxManager: {};
  private coreManager: {};
  private userManager: {};
  private queManager: {};
  private hrmManager: {};
  private msgManager: {};
  private regManager: {};
  private bilManager: {};
  private lineLoginManager: {};
  private lastReadMessage: string | number | null;
  private queueController: {};
  private chatController?: {};
  private regChatController?: {};
  private classifyController: {};
  private bilPatientPanelController: {};
  private diagFormController: {};

  constructor(props) {
    super(props);

    this.mainScreen = React.createRef();
    this.controller = new AppController(
      () => {
        return this.state;
      },
      (state) => {
        this.setState(state);
      },
      window
    );

    const { cookies } = props;

    this.state = {
      division: cookies.get("division"),
      apiToken: cookies.get("apiToken"),
      userId: cookies.get("userId"),
      fullname: cookies.get("fullname"),
      username: cookies.get("username"),
      landingLoading: false,
      providerId: null,
      theme: null,
      myDivisions: [],
      currentDivision: null,
      finishedTriageLevel: [],
      providerInfo: {},
      credentailLoginSuccess: false,
    };

    this.mixInManager = new MixInManager();
    this.lineLoginManager = new LineLoginManager();
    this.prxManager = new PRXManager();
    this.coreManager = new CoreManager();
    this.userManager = new UserManager();
    this.queManager = new QUEManager();
    this.hrmManager = new HRMManager();
    this.msgManager = new MSGManager();
    this.regManager = new REGManager();
    this.bilManager = new BILManager();

    this.loginController = new IsHealthCommon.LoginController({
      mixInManager: this.mixInManager,
    });

    this.regChatController = new REGChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
    });

    this.chatController = new ChatController({
      msgManager: this.msgManager,
      prxManager: this.prxManager,
      regManager: this.regManager,
      coreManager: this.coreManager,
    });

    this.queueController = new QueueController({
      queManager: this.queManager,
      prxManager: this.prxManager,
      hrmManager: this.hrmManager,
    });

    this.classifyController = new CardClassifyController({
      coreManager: this.coreManager,
      prxManager: this.prxManager,
      userManager: this.userManager,
    });

    this.bilPatientPanelController = new BilPatientPanelController({
      coreManager: this.coreManager,
      bilManager: this.bilManager,
    });

    this.diagFormController = new CardDiagFormHistoryController({
      prxManager: this.prxManager,
    });
    this.dashboardController = new DashboardController({
      prxManager: this.prxManager,
      coreManager: this.coreManager,
    });
    this.appointmentDashboardController = new AppointmentDashboardController({
      coreManager: this.coreManager,
      prxManager: this.prxManager,
    });
    this.bindHNPageController = new BindHNPageController({
      prxManager: this.prxManager,
    });

    this.nurseNoteController = new CardNurseNoteController({
      prxManager: this.prxManager,
      ptmManager: this.ptmManager,
    });

    this.crdVitalSignController = new CardVitalSignController({
      ptmManager: this.ptmManager,
    });

    this.progressNoteController = new CardProgressNoteController({
      dpiManager: this.dpiManager,
      prxManager: this.prxManager,
    });

    this.patientPanelController = new CardPatientPanelController({
      regManager: this.regManager,
      coreManager: this.coreManager,
      prxManager: this.prxManager,
    });

    vcMessenger.onMessage((message) => {
      console.log(message, this.mainScreen?.qaChatTable)
      if (message["data_message_type"] === "MESSAGE") {
        this.mainScreen.chatTable?.chatListRef?.getChatList?.();
        if (this.mainScreen.chatTable?.chatBoxRef) {
          this.mainScreen.chatTable.chatBoxRef.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }
        if (config.CLASSIFY_CHAT || config.AIRFLOW_CHAT) {
          if (this.mainScreen.qaChatTable) {
            if (this.mainScreen.qaChatTable.chatBoxRef) {
              this.mainScreen.qaChatTable.chatBoxRef.getLastMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
            if (this.mainScreen.qaChatTable.chatBoxRef) {
              this.mainScreen.qaChatTable.chatBoxRef.receivedMessage({
                chatChannelId: parseInt(message["channel_id"]),
              });
            }
            this.mainScreen.qaChatTable.receivedMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
        }

      } else if (message["data_message_type"] === "MESSAGE_READ") {
        if (this.lastReadMessage === message.message_id) {
          return;
        }
        this.lastReadMessage = message.message_id;
        if (this.mainScreen.chatTable?.chatBoxRef) {
          this.mainScreen.chatTable.chatBoxRef.receivedMessageRead({
            chatChannelId: parseInt(message["channel_id"]),
            messageId: parseInt(message["message_id"]),
          });
        }
        if (this.mainScreen.qaChatTable) {
          if (this.mainScreen.qaChatTable.chatBoxRef) {
            this.mainScreen.qaChatTable.chatBoxRef.getLastMessage({
              chatChannelId: parseInt(message["channel_id"]),
            });
          }
          this.mainScreen.qaChatTable.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }
      } else if (message["event"] === "RECLASSIFY") {
        if (this.mainScreen.qaChatTable) {
          this.mainScreen.qaChatTable.getListTriageLevel();
        }
        if (this.mainScreen.dashboard) {
          this.mainScreen.dashboard.refresh();
        } else {
          this.mainScreen.chatTable?.chatListRef?.getChatList?.({
            type: "RECLASSIFY",
          });
          this.mainScreen.chatTable.getPatientInfo();
          this.props.history.push("/Chat/");
        }
        // let filterValue = this.mainScreen.chatTable.chatListRef.getFilterValue();
        // this.mainScreen.chatTable.chatBoxRef.receivedReclassify({
        //   filterValue: filterValue,
        //   encounterId: parseInt(message["encounter"]),
        //   chatChannelId: parseInt(message["chat_channel"])
        // });
      } else if (message["event"] === "SET_HN") {
        this.mainScreen.chatTable.chatListRef.getChatList();
      } else if (message["event"] === "DIAG_FORM_UPDATED") {
        if (this.mainScreen.qaChatTable) {
          this.mainScreen.qaChatTable.updateDiagForm({
            patientId: parseInt(message["patient_id"]),
          });
        }
      }
    });
  }

  componentDidMount() {
    let currentURL = window.location.href;

    if (currentURL.includes(URL_PREFIX.PENGUIN)) {
      this.setState({ theme: THEME.PENGUIN });
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
      this.props.cookies.set("theme", THEME.PENGUIN, { path: "/" });
      // Theme for penguin
    } else {
      this.setState({ theme: THEME.PENTA });
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
      this.props.cookies.set("theme", THEME.PENTA, { path: "/" });
    }
    let userId = this.props.cookies.get("userId")
      ? this.props.cookies.get("userId")
      : this.state.userId;
    let apiToken = this.props.cookies.get("apiToken")
      ? this.props.cookies.get("apiToken")
      : this.state.apiToken;
    if (apiToken) {
      this.setWebsocket({ apiToken });
    }
    if (userId) {
      this.getProviderInfo({ userId });
      this.lagacyLoginFirebase(userId)
    }
    // this.handleGetFinishedTriageLevel();
  }

  handleLoginSuccess = async (response: {
    profile?: {
      fullname?: string;
      username?: string;
      userId?: string | number;
    };
    token?: string;
  }) => {


    if (response && response.profile && response.profile.userId) {
      this.lagacyLoginFirebase(response.profile.userId)
    }

    this.props.cookies.set("fullname", response.profile.fullname, {
      path: "/",
    });
    this.props.cookies.set("username", response.profile.username, {
      path: "/",
    });
    this.props.cookies.set("apiToken", response.token, { path: "/" });
    this.props.cookies.set("userId", response.profile.userId, { path: "/" });
    this.getProviderInfo({ userId: response.profile.userId });
    this.setState({
      userId: response.profile.userId,
      apiToken: response.token,
    });
    this.setWebsocket({ apiToken: response.token });
    this.setState({ landingLoading: false });
    this.props.history.push({
      pathname: "/Chat",
    });
  };

  handleCredentialLoginSuccess = async (response) => {
    console.log(" handleCredentialLoginSuccess");
    this.props.cookies.set("fullname", response?.profile?.fullname, {
      path: "/",
    });
    this.props.cookies.set("username", response?.profile?.username, {
      path: "/",
    });
    this.props.cookies.set("apiToken", response.token, { path: "/" });
    this.props.cookies.set("userId", response?.profile?.userId, { path: "/" });
    this.getProviderInfo({ userId: response?.profile?.userId });
    this.setState({
      userId: response?.profile?.userId,
      apiToken: response.token,
    });
    this.setWebsocket({ apiToken: response.token });
    this.setState({ landingLoading: false });
    this.setState({ credentailLoginSuccess: true });
    this.props.history.push({
      pathname: "/bind-hns",
    });
  };

  handleLineLoginSuccess = (loginRes) => {
    if (loginRes.pentaToken) {
      this.setState({ landingLoading: true });
      setTimeout(() => {
        this.handleLoginSuccess(loginRes.pentaToken);
      }, 0);
    } else {
      this.setState({ landingLoading: false });
      this.props.history.replace({ pathname: "/Login" });
    }
  };

  handleLineLoginFailed = (props) => {
    this.setState({ landingLoading: false });
    console.log("handleLineLoginFailed: ", props);
    this.props.history.replace({ pathname: "/Login" });
  };

  getProviderInfo = async ({ userId }: { userId?: number | string } = {}) => {
    const [response, error] = await this.queueController.getProviderInfo({
      apiToken: this.props.cookies.get("apiToken")
        ? this.props.cookies.get("apiToken")
        : this.state.apiToken,
      userId,
    });
    if (response) {
      this.setState({ providerId: response.id, providerInfo: response });
      this.props.cookies.set("providerId", response.id, { path: "/" });
    } else {
      this.setState({ providerInfo: {} });
    }
  };

  setWebsocket = ({ apiToken }: { apiToken?: string } = {}) => {
    vcMessenger.connect(
      "MSG",
      {
        screens: this.props.cookies.get("division"),
        token: apiToken,
      },
      config.WS_HOST
    );
  };

  getRightMenuItem = () => {
    if (
      this.state.apiToken ||
      this.props.cookies.get("apiToken") ||
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.userId)
    ) {
      return (
        <Menu.Item position="right">
          <h3>
            {this.state.providerInfo.employee_info
              ? this.state.providerInfo.employee_info.full_name
              : ""}
          </h3>
          <Button onClick={this.handleLogout}>Logout</Button>
        </Menu.Item>
      );
    } else if (this.props.location.pathname === "/") {
      return (
        <Menu.Item position="right">
          <Button
            onClick={(e) => {
              this.props.history.push("/Login");
            }}
          >
            Login/Register
          </Button>
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  getLeftMenuItem = () => {
    if (this.props.location.pathname === "/") {
      return null;
    } else {
      return (
        <Menu.Item>
          <h2
          // onClick={this.handleHomeIconClick}
          >
            {`IsHealth REG`}
          </h2>
        </Menu.Item>
      );
    }
  };

  getDivisionMenuItem = () => {
    if (this.props.history.location.pathname.includes("Dashboard")) {
      return <></>;
    }
    if (
      this.state.apiToken ||
      this.props.cookies.get("apiToken") ||
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.userId)
    ) {
      return (
        <Menu.Item>
          <Dropdown
            button
            className={"icon"}
            floating
            labeled
            icon={"hospital"}
            options={this.state.myDivisions}
            search
            value={
              this.state.currentDivision ? this.state.currentDivision.value : ""
            }
            placeholder={"Select Division"}
            onChange={this.handleChangeDivision}
          />
        </Menu.Item>
      );
    } else {
      return null;
    }
  };

  loadMydivision = async () => {
    let apiToken = this.props.cookies.get("apiToken")
      ? this.props.cookies.get("apiToken")
      : this.state.apiToken;
    if (!apiToken) {
      return;
    }
    const [response, error, network] = await this.prxManager.getDivisionHasUser(
      { apiToken: apiToken }
    );
    if (response && response.items.length > 0) {
      console.log("my division", response.items);
      var myDivisions = [];
      for (let d of response.items) {
        var dupicate = false;
        for (let m of myDivisions) {
          if (m.id === d.division.id) {
            dupicate = true;
            break;
          }
        }
        if (!dupicate) {
          myDivisions.push(d.division);
        }
      }
      // Prepare data to dropdown element
      var options = [];
      for (var division of myDivisions) {
        options.push({
          ...division,
          text: division.name,
          value: division.code,
        });
      }
      this.setState({ myDivisions: options });

      // Initial division
      var targetDivision = null;
      let selectedDivision = this.props.cookies.get("division");
      if (selectedDivision) {
        const selected = options.find(
          (division) => division.id.toString() === selectedDivision
        );
        if (selected) {
          targetDivision = selected;
        }
      }
      // No dafault division
      if (!targetDivision && options.length > 0) {
        targetDivision = options[0];
        this.props.cookies.set("division", targetDivision.id, { path: "/" });
      }

      if (targetDivision) {
        this.setState({
          currentDivision: targetDivision,
          division: targetDivision.id,
        });
        vcMessenger.reconnect({
          screens: targetDivision.id,
          token: this.props.cookies.get("apiToken")
            ? this.props.cookies.get("apiToken")
            : this.state.apiToken,
        });
      } else {
        return console.log("There aren't division");
        // alert("ผู้ใช้งานนี้ไม่มี โรงพยาบาล (Division) ของระบบ กรุณาติดต่อ ผู้ดูแล");
      }
    }
  };

  handleChangeDivision = (event, data) => {
    let sValue = data.value;
    const selected = this.state.myDivisions.find(
      (division) => division.value === sValue
    );
    if (selected) {
      this.setState({
        currentDivision: selected,
        division: selected.id,
      });
      vcMessenger.reconnect({
        screens: selected.id,
        token: this.props.cookies.get("apiToken")
          ? this.props.cookies.get("apiToken")
          : this.state.apiToken,
      });
      this.props.cookies.set("division", selected.id, { path: "/" });
    }
  };

  handleLogout = async () => {
    serviceWorker.unregister();
    if (window.FB) {
      window.FB.logout();
    }
    console.log("unregister service worker");
    // Remove data
    this.props.cookies.remove("fullname", { path: "/" });
    this.props.cookies.remove("username", { path: "/" });
    this.props.cookies.remove("apiToken", { path: "/" });
    this.props.cookies.remove("division", { path: "/" });
    this.props.cookies.remove("userId", { path: "/" });
    this.setState({
      apiToken: this.props.cookies.apiToken,
      userId: this.props.cookies.userId,
      credentailLoginSuccess: false,
    });
    this.props.history.push({ pathname: "/", state: {} });
  };

  componentWillUpdate(nextProps, nextState) {
    console.log(this.state.division, nextState.division);
    if (this.state.division != nextState.division) {
      if (!this.state.credentailLoginSuccess) {
        this.props.history.push("/Chat/");
      } else {
        this.setState({ credentailLoginSuccess: false });
      }
    }
  }

  reconnectWs = () => {
    vcMessenger.reconnect({
      screens: this.state.division,
      token: this.props.cookies.get("apiToken")
        ? this.props.cookies.get("apiToken")
        : this.state.apiToken,
    });
  };

  lagacyLoginFirebase = (userId) => {

    this.controller.functions.httpsCallable("createTokenFromUser")(userId.toString())
      .then((result) => {
        // console.log("Token", result.data);
        this.setState({ firebaseToken: result.data });
        this.controller.app
          .auth()
          .signInWithCustomToken(result.data)
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

  }

  render() {
    return (
      <CookiesProvider>
        <div className="main-bil" data-testid="main-bil">
          <Switch>
            <Route
              exact
              path="/EmailLogin/"
              render={(props) => {
                return <EmailLogin {...props} />;
              }}
            />
            <Route
              exact
              path="/EmailRegister/"
              render={(props) => {
                return <EmailRegister {...props} />;
              }}
            />
            <Route
              exact
              path="/email-callback/"
              render={(props) => {
                return (
                  <EmailCallback
                    onLoginSuccess={this.handleLoginSuccess}
                    onLoginFailed={this.handleLineLoginFailed}
                  />
                );
              }}
            />
            <Route
              exact
              path="/bind-hn/"
              render={(props) => {
                return (
                  <ResolveBindHN
                    {...props}
                    apiToken={this.state.apiToken}
                    // controller={this.ChatController}
                    onLoginSuccess={this.handleCredentialLoginSuccess}
                  />
                );
              }}
            />
            <Route exact path="/callback/">
              <IsHealthCommon.LineCallBack
                onLoginSuccess={this.handleLineLoginSuccess}
                onLoginFailed={this.handleLineLoginFailed}
              />
            </Route>
            <Route exact path="/Login">
              <REGLogin
                controller={this.loginController}
                lineLoginManager={this.lineLoginManager}
                onLoginSuccess={this.handleLoginSuccess}
                onHaveApiToken={() => {
                  this.props.history.push("/Chat");
                }}
              />
            </Route>
            <Route exact path="/cert-login">
              <CertLogin onLoginSuccess={this.handleLoginSuccess} />
            </Route>
            <Route
              path="/"
              render={(props) => {
                if (this.state.apiToken) {
                  return (
                    <MainScreen
                      {...props}
                      ref={(ref) => (this.mainScreen = ref)}
                      getLeftMenuItem={this.getLeftMenuItem}
                      getDivisionMenuItem={this.getDivisionMenuItem}
                      getRightMenuItem={this.getRightMenuItem}
                      regChatController={this.regChatController}
                      chatController={this.chatController}
                      bilPatientPanelController={this.bilPatientPanelController}
                      patientPanelController={this.patientPanelController}
                      classifyController={this.classifyController}
                      diagFormController={this.diagFormController}
                      apiToken={this.state.apiToken}
                      onGetDivision={this.loadMydivision}
                      division={this.state.division}
                      userId={this.state.userId}
                      fullname={this.state.fullname}
                      username={this.state.username}
                      finishedTriageLevel={this.state.finishedTriageLevel}
                      queueController={this.queueController}
                      dashboardController={this.dashboardController}
                      appointmentDashboardController={
                        this.appointmentDashboardController
                      }
                      onReconnectWs={this.reconnectWs}
                      bindHNPageController={this.bindHNPageController}
                      crdVitalSignController={this.crdVitalSignController}
                      nurseNoteController={this.nurseNoteController}
                      progressNoteController={this.progressNoteController}
                      onSetState={(state) => this.setState({ ...state })}
                      storage={this.controller.storage}
                    />
                  );
                } else {
                  return (
                    <IsHealthCommon.LandingPage
                      detail={
                        <div style={{ marginTop: "10%" }}>
                          <span className="header darkblue">Is</span>
                          <span className="header">Health REG</span>
                          <div>
                            แพลตฟอร์มสำหรับเวชระเบียน ที่ใช้ในการสื่อสาร
                            การลงทะเบียนและการผูกประวัติผู้ป่วยผ่านระบบออนไลน์
                          </div>
                          <br />
                          <div>
                            <Button
                              id="start_login_btn"
                              data-testid="start_login_btn"
                              content="คลิกเพื่อเริ่มต้นใช้งานระบบ"
                              size="huge"
                              className="whiteBasicButton"
                              onClick={() =>
                                (window.location.pathname = "/Login")
                              }
                            />
                          </div>
                        </div>
                      }
                    />
                  );
                }
              }}
            />
          </Switch>
        </div>
      </CookiesProvider>
    );
  }
}

export default withCookies(withRouter(MainREG));
