import React, { useRef, useImperativeHandle, forwardRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Menu } from "semantic-ui-react";
import ChatTable from "./REGChatTable";
import { Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ModSelectAppointment from "../react-lib/apps/IsHealth/Common/ModSelectAppointment";
import BindHNPage from "../react-lib/apps/IsHealth/REG/BindHNPage";
import Dashboard from "../react-lib/apps/IsHealth/Dashboard/Dashboard";
import AppointmentDashboard from "../react-lib/apps/IsHealth/APP/AppointmentDashboard";
import config from "../config/config";
import QAChatTable from "../react-lib/apps/IsHealth/Platform/QAChatTable";

const PAGE = {
  CHAT: "/Chat",
  SCHEDULE: "/Schedule",
  MY_SCHEDULE: "/MySchedule",
  DASHBOARD: "/Dashboard",
  APPOINTMENT_DASHBOARD: "/AppointmentDashboard",
  BIND_HN: "bind-hn",
  CLASSIFY_CHAT: "/ClassifyChat",
  AIRFLOW_CHAT: "/IsolationChat",
};

interface MainScreenProps { }

const MainScreen = forwardRef((props: MainScreenProps, ref) => {
  const [currentPage, setCurrentPage] = useState("");
  const [
    openModChooseAppointment,
    setOpenModChooseAppointment
  ] = useState(false);
  const [patientAppointmentId, setPatientAppointmentId] = useState(null);

  const chatTable = useRef();
  const qaChatTable = useRef();
  const dashboardRef = useRef();
  const history = useHistory();

  useImperativeHandle(ref, () => ({
    chatTable: chatTable.current,
    dashboard: dashboardRef.current,
    qaChatTable: qaChatTable.current
  }));

  useEffect(() => {
    if (currentPage !== PAGE.DASHBOARD) {
      props.onReconnectWs()
    }
  }, [currentPage])

  useEffect(() => {
    if (history.location.pathname.includes(PAGE.CLASSIFY_CHAT)) {
      setCurrentPage(PAGE.CLASSIFY_CHAT);
    } else if (history.location.pathname.includes(PAGE.AIRFLOW_CHAT)) {
      setCurrentPage(PAGE.AIRFLOW_CHAT);
    } else if (history.location.pathname.includes(PAGE.CHAT)) {
      setCurrentPage(PAGE.CHAT);
    } else if (history.location.pathname.includes(PAGE.SCHEDULE)) {
      setCurrentPage(PAGE.SCHEDULE);
    } else if (history.location.pathname.includes(PAGE.MY_SCHEDULE)) {
      setCurrentPage(PAGE.MY_SCHEDULE);
    } else if (history.location.pathname.includes(PAGE.DASHBOARD)) {
      setCurrentPage(PAGE.DASHBOARD);
    } else if (history.location.pathname.includes(PAGE.APPOINTMENT_DASHBOARD)) {
      setCurrentPage(PAGE.APPOINTMENT_DASHBOARD);
    } else if (history.location.pathname.includes(PAGE.BIND_HN)) {
      setCurrentPage(PAGE.BIND_HN)
    } else {
      history.push("/Chat");
    }
  }, [history.location]);

  useEffect(() => {
    props.onGetDivision();
  }, []);

  const handleCreateAppointment = ({ contentPayload, appointmentId } = {}) => {
    let id = contentPayload
      ? contentPayload.patient_appointment
      : appointmentId;
    setPatientAppointmentId(id);
    setOpenModChooseAppointment(true);
  };

  const handleNavigationMessage = ({
    content = "",
    contentPayload = null
  } = {}) => {
    if (content.includes("{ตารางออกตรวจผู้ให้คำปรึกษา}")) {
      let contentArr = content.split("{ตารางออกตรวจผู้ให้คำปรึกษา}");
      return (
        <div>
          {contentArr.map((item, index) => {
            if (index !== contentArr.length - 1) {
              return (
                <>
                  {item}
                  <span
                    className="textButton"
                    onClick={() => handleCreateAppointment({ contentPayload })}
                  >
                    ตารางออกตรวจผู้ให้คำปรึกษา
                  </span>
                </>
              );
            }
          })}
        </div>
      );
    } else {
      return content;
    }
  };

  return (
    <div className="MainScreen">
      <ModSelectAppointment
        controller={props.queueController}
        open={openModChooseAppointment}
        onClose={() => {
          setPatientAppointmentId(null);
          setOpenModChooseAppointment(false);
        }}
        patientAppointmentId={patientAppointmentId}
        division={props.division}
      />
      <Menu secondary>
        {props.getLeftMenuItem()}
        {props.getDivisionMenuItem()}
        {props.getRightMenuItem()}
      </Menu>
      <Grid>
        <Grid.Column className="tab">
          {config.DASHBOARD && (
            <Button
              className="tabButton"
              icon="pie chart"
              active={currentPage === PAGE.DASHBOARD}
              onClick={() => history.push("/Dashboard/")}
            />
          )}
          {config.DASHBOARD && (
            <Button
              className="tabButton"
              icon="users"
              active={currentPage === PAGE.APPOINTMENT_DASHBOARD}
              onClick={() => history.push("/AppointmentDashboard/")}
            />
          )}
          <Button
            className="tabButton"
            icon="chat"
            active={currentPage === PAGE.CHAT}
            onClick={() => history.push("/Chat/")}
          />
          {config.CLASSIFY_CHAT && (
            <Button
              className="tabButton"
              icon="comment alternate outline"
              active={currentPage === PAGE.CLASSIFY_CHAT}
              onClick={() => history.push("/ClassifyChat/")}
            />
          )}
          {config.AIRFLOW_CHAT && (
            <Button
              className="tabButton"
              icon="comment alternate"
              active={currentPage === PAGE.AIRFLOW_CHAT}
              onClick={() => history.push("/IsolationChat/")}
            />
          )}
          {config.SHOW_BIND_HN && (<Button
            className="tabButton"
            icon="address book"
            active={currentPage === PAGE.BIND_HN}
            onClick={() => history.push("/bind-hns/")}
          />)}
        </Grid.Column>
        <Grid.Column className="page">
          <Route
            exact
            path="/Chat/:patientId?/:chatChannelId?/"
            render={chatProps => (
              <ChatTable
                {...props}
                {...chatProps}
                ref={chatTable}
                // ref={ref => (this.chatTable = ref)}
                allowCreateAppoint={true}
                controller={props.regChatController}
                diagFormController={props.diagFormController}
                patientPanelController={props.bilPatientPanelController}
                nurseNoteController={props.nurseNoteController}
                progressNoteController={props.progressNoteController}
                classifyController={props.classifyController}
                apiToken={props.apiToken}
                onGetDivision={props.loadMydivision}
                division={props.division}
                userId={props.userId}
                fullname={props.fullname}
                username={props.username}
                finishedTriageLevel={props.finishedTriageLevel}
                onNavigationMessage={handleNavigationMessage}
                onEditAppointment={handleCreateAppointment}
                isPatient={false}
              />
            )}
          />
          {config.DASHBOARD && (
            <Route
              exact
              path="/AppointmentDashboard/"
              render={appProps => (
                <AppointmentDashboard
                  {...props}
                  {...appProps}
                  controller={props.appointmentDashboardController}
                />
              )}
            />
          )}
          {config.DASHBOARD && (
            <Route
              exact
              path="/Dashboard/"
              render={appProps => (
                <Dashboard
                  {...appProps}
                  apiToken={props.apiToken}
                  division={props.division}
                  controller={props.dashboardController}
                  ref={dashboardRef}
                />
              )}
            />
          )}

          {config.CLASSIFY_CHAT && (
            <Route
              exact
              path="/ClassifyChat/:triageLevel?/:diagFormId?"
              render={(chatProps) => (
                <QAChatTable
                  apiToken={props.apiToken}
                  division={props.division}
                  finishedTriageLevel={props.finishedTriageLevel}
                  {...props}
                  {...chatProps}
                  ref={qaChatTable}
                  // ref={ref => (this.chatTable = ref)}
                  allowCreateAppoint={config.BOOKING_FUNCTION ? true : false}
                  // Controller
                  controller={props.chatController}
                  diagFormController={props.diagFormController}
                  patientPanelController={props.patientPanelController}
                  nurseNoteController={props.nurseNoteController}
                  progressNoteController={props.progressNoteController}
                  classifyController={props.classifyController}
                  crdVitalSignController={props.crdVitalSignController}
                  onGetDivision={props.onGetDivision}
                  dashboardController={props.dashboardController}
                  queueController={props.queueController}
                  // Data
                  userId={props.userId}
                  fullname={props.fullname}
                  username={props.username}
                  finishedTriageLevel={props.finishedTriageLevel}
                  storage={props.storage}
                  isPatient={false}
                  // Callback
                  onNavigationMessage={handleNavigationMessage}
                  onEditAppointment={handleCreateAppointment}
                  onSetShowMenuNoti={(show) =>
                    props.onSetState({ showMenuNoti: show })
                  }
                />
              )}
            />
          )}
          {config.AIRFLOW_CHAT && (
            <Route
              exact
              path="/IsolationChat/:triageLevel?/:diagFormId?"
              render={(chatProps) => (
                <QAChatTable
                  apiToken={props.apiToken}
                  division={props.division}
                  finishedTriageLevel={props.finishedTriageLevel}
                  {...props}
                  {...chatProps}
                  ref={qaChatTable}
                  // ref={ref => (this.chatTable = ref)}
                  allowCreateAppoint={config.BOOKING_FUNCTION ? true : false}
                  // Controller
                  controller={props.chatController}
                  diagFormController={props.diagFormController}
                  patientPanelController={props.patientPanelController}
                  nurseNoteController={props.nurseNoteController}
                  progressNoteController={props.progressNoteController}
                  classifyController={props.classifyController}
                  crdVitalSignController={props.crdVitalSignController}
                  onGetDivision={props.onGetDivision}
                  dashboardController={props.dashboardController}
                  queueController={props.queueController}
                  // Data
                  userId={props.userId}
                  fullname={props.fullname}
                  username={props.username}
                  finishedTriageLevel={props.finishedTriageLevel}
                  storage={props.storage}
                  isPatient={false}
                  // Callback
                  onNavigationMessage={handleNavigationMessage}
                  onEditAppointment={handleCreateAppointment}
                  // onSetShowMenuNoti={(show) =>
                  //   props?.onSetState({ showMenuNoti: show })
                  // }
                  // config
                  AIRFLOW_CHAT={true}
                />
              )}
            />
          )}
          {config.SHOW_BIND_HN && (<Route
            exact
            path="/bind-hns/"
            render={appProps => (
              <BindHNPage
                {...appProps}
                controller={props.bindHNPageController}
                apiToken={props.apiToken}
                division={props.division}
              />
            )}
          />)}
        </Grid.Column>
      </Grid>
    </div>
  );
});

MainScreen.defaultProps = {
  diagFormController: {},
  chatController: {},
  regChatController: {},
  patientPanelController: {},
  nurseNoteController: {},
  progressNoteController: {},
  classifyController: {},
  onGetDivision: () => { },
  finishedTriageLevel: [],
  queueController: {},
  storage: {},
};

MainScreen.propTypes = {
  diagFormController: PropTypes.object,
  chatController: PropTypes.object,
  regChatController: PropTypes.object,
  patientPanelController: PropTypes.object,
  nurseNoteController: PropTypes.object,
  progressNoteController: PropTypes.object,
  classifyController: PropTypes.object,
  apiToken: PropTypes.string,
  onGetDivision: PropTypes.func,
  division: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullname: PropTypes.string,
  username: PropTypes.string,
  finishedTriageLevel: PropTypes.array,
  queueController: PropTypes.object,
  storage: PropTypes.object,
};

export default React.memo(MainScreen);
